<template>
	<div v-if="enablePrescription && orderPrescriptions.length > 0">
		<!-- Count -->
		<p class="text-uppercase text-muted mx-2">{{ prescriptionCountText }}</p>

		<!-- Card -->
		<b-row>
			<b-col sm="6" lg="4" v-for="orderPrescription in orderPrescriptions" :key="orderPrescription.id">
				<order-prescription-card :orderPrescription="orderPrescription" class="my-2" />
			</b-col>
		</b-row>

		<hr />
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OrderPrescriptionCard from "./OrderPrescriptionCard.vue";

export default {
	components: { OrderPrescriptionCard },
	created() {
		if (this.enablePrescription) {
			this.makeApiCall();
		}
	},
	props: {
		order: {
			type: Object,
			required: true,
		},
		isMerchant: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			orderPrescriptions: [],
		};
	},
	computed: {
		...mapGetters({
			enablePrescription: "getEnablePrescription",
		}),
		prescriptionCountText() {
			const count = this.orderPrescriptions.length;
			if (count == 1) {
				return "1 Prescription";
			}
			return `${count} Prescriptions`;
		},
	},
	methods: {
		...mapActions({
			getUserOrderPrescriptions: "user/orders/getOrderPrescriptions",
			getMerchantOrderPrescriptions: "merchant/orders/getOrderPrescriptions",
		}),
		makeApiCall() {
			if (this.isMerchant) {
				this.getMerchantOrderPrescriptions(this.order.id).then((data) => {
					this.orderPrescriptions = [...data];
				});
			} else {
				this.getUserOrderPrescriptions(this.order.id).then((data) => {
					this.orderPrescriptions = [...data];
				});
			}
		},
	},
};
</script>

<style></style>
