import themes from "./themes";

export const miniPrescriptionImageProps = () => {
	return {
		width: 60,
		height: 60,
		center: true,
	};
};

export const fullPrescriptionImageProps = () => {
	return {
		width: 300,
		height: 300,
		fluid: true,
		center: true,
	};
};

export const orderImageProps = async (blank) => {
	const cssVariables = await themes;
	return {
		blank,
		blankColor: cssVariables.appGray3,
		width: 90,
		height: 90,
		rounded: true,
	};
};

export const productImageProps = async (blank) => {
	const cssVariables = await themes;
	return {
		blank,
		blankColor: cssVariables.appGray3,
		height: 150,
		rounded: true,
		width: 150,
	};
};

export const quantitySpinData = (unit) => {
	const hundreds = { initial: 100, increment: 50, min: 50, max: 10000 };
	const onesWithPointIncrement = {
		initial: 1,
		increment: 0.25,
		min: 0.25,
		max: 100,
	};
	const onesIncrement = { initial: 1, increment: 1, min: 1, max: 100 };

	if (unit == "gm") return hundreds;
	if (unit == "ml") return hundreds;

	if (unit == "kg") return onesWithPointIncrement;
	if (unit == "ltr") return onesWithPointIncrement;
	if (unit == "dozen") return onesWithPointIncrement;
	if (unit == "ft") return onesWithPointIncrement;
	if (unit == "meter") return onesWithPointIncrement;
	if (unit == "sq_ft") return onesWithPointIncrement;
	if (unit == "sq_meter") return onesWithPointIncrement;

	if (unit == "set") return onesIncrement;
	if (unit == "piece") return onesIncrement;

	if (unit == "km") return onesIncrement;

	if (unit == "hour") return onesIncrement;
	if (unit == "day") return onesIncrement;
	if (unit == "month") return onesIncrement;
	if (unit == "year") return onesIncrement;
	return onesIncrement;
};
