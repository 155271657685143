<template>
	<div>
		<div class="d-flex m-2">
			<!-- Image -->
			<b-img v-bind="imgProps" :src="imageSrc" class="m-2" />

			<!-- Item Data -->
			<div class="flex-fill align-self-center ml-2">
				<!-- Item Name -->
				<p class="text-capitalize no-gutters">{{ item.productName }}</p>

				<div class="d-flex justify-content-between">
					<!-- Quantity -->
					<div>
						<span class="border rounded px-1 py-1 mr-1 font-weight-bold">
							{{ item.quantity }}
						</span>
						{{ unit }}
						x
						{{ priceToDisplay | rupeeCurrency }}
					</div>

					<!-- Price -->
					<div>
						{{ totalPriceToDisplay.toString() | rupeeCurrency }}
					</div>
				</div>
			</div>
		</div>

		<!-- Modal to edit price and quantity -->
		<template v-if="priceAndQuantityEditable">
			<b-button @click="editItemClicked" size="sm" block>Edit Item</b-button>
			<hr />

			<b-modal size="lg" v-model="showOrderItemModal" @hidden="closeModal" hide-header hide-footer centered>
				<h4 class=" text-center mb-4">{{ item.productName }}</h4>

				<!-- Form Fields -->
				<b-row no-gutters>
					<b-col>
						<!-- Quantity Spinner -->
						<b-form-group label="Quantity*" label-for="quantity" :invalid-feedback="getErrorMessage(errors, 'quantity')" :state="getErrorState(errors, 'quantity')">
							<b-form-spinbutton :state="getErrorState(errors, 'quantity')" id="quantity" v-model="editableQuantity" min="0" :step="quantitySpinData(unit).increment" :max="quantitySpinData(unit).max" inline />
						</b-form-group>
					</b-col>
					<b-col>
						<!-- Price -->
						<b-form-group label="Price*" label-for="productPrice" :invalid-feedback="getErrorMessage(errors, 'productPrice')" :state="getErrorState(errors, 'productPrice')">
							<b-form-input :state="getErrorState(errors, 'productPrice')" id="productPrice" v-model="editableProductPrice" placeholder="Price" @keypress="isNumber($event)" trim />
						</b-form-group>
					</b-col>
				</b-row>

				<!-- Buttons -->
				<div class="text-center">
					<b-button @click="confirmOrderItem" variant="primary" class="mr-3">
						Confirm
					</b-button>
					<b-button @click="closeModal">
						Close
					</b-button>
				</div>
			</b-modal>
		</template>
	</div>
</template>

<script>
import { orderImageProps, quantitySpinData } from "../../app-functions";
import { mapGetters } from "vuex";

import { updateOrderItem } from "../../apis/merchant";
export default {
	props: {
		orderId: {
			type: Number,
			required: true,
		},
		orderItem: {
			type: Object,
			required: true,
		},
		priceAndQuantityEditable: {
			type: Boolean,
		},
	},
	created() {
		this.item = {
			...this.orderItem,
		};
		orderImageProps(Boolean(!this.imageSrc)).then((data) => (this.imgProps = { ...data }));
	},
	data() {
		return {
			imgProps: {},
			item: {
				id: 0,
				defaultProductImage: null,
				quantity: 0,
				productName: "",
				productUnit: "",
				productPrice: null,
			},

			// for editable
			showOrderItemModal: false,
			editableQuantity: 0,
			editableProductPrice: null,
			errors: null,
		};
	},
	computed: {
		...mapGetters({
			token: "login/getToken",
		}),
		imageSrc() {
			const productImage = this.item.defaultProductImage;
			if (productImage && productImage.blobUrl) {
				return productImage.blobUrl;
			}
			return null;
		},
		priceToDisplay() {
			const price = this.item.productPrice;
			if (!price) {
				return "**";
			}
			return parseFloat(price).toFixed(2);
		},
		totalPriceToDisplay() {
			const price = this.item.productPrice;
			if (!price) {
				return "**";
			}
			const totalPrice = this.item.quantity * price;
			return totalPrice.toFixed(2);
		},
		unit() {
			return this.item.productUnit;
		},
	},
	methods: {
		quantitySpinData,

		editItemClicked() {
			this.resetEditableValues();
			this.showOrderItemModal = true;
		},
		resetEditableValues() {
			if (this.priceAndQuantityEditable) {
				this.editableQuantity = this.item.quantity;
				this.editableProductPrice = this.item.productPrice;
			}
		},
		confirmOrderItem() {
			// Cant edit, return
			if (!this.priceAndQuantityEditable) return;

			const quantity = this.editableQuantity;
			const productPrice = this.editableProductPrice;
			updateOrderItem(this.orderId, this.orderItem.id, { quantity, productPrice }, this.token)
				.then((data) => {
					this.item.quantity = quantity;
					this.item.productPrice = productPrice;
					this.showOrderItemModal = false;
					this.$emit("confirmItemUpdate", data);
				})
				.catch(({ data }) => {
					this.errors = data.errors;
				});
		},
		closeModal() {
			this.resetEditableValues();
			this.showOrderItemModal = false;
		},
		isNumber: function(evt) {
			evt = evt ? evt : window.event;
			var charCode = evt.which ? evt.which : evt.keyCode;
			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
	},
};
</script>
