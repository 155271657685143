<template>
	<div class="mt-4 mx-2">
		<!-- Title -->
		<b-card-text class="text-muted text-uppercase font-weight-normal mb-3">
			Customer's detail
		</b-card-text>

		<!-- Name -->
		<!-- <b-row class="text-capitalize font-weight-bold mb-2">
        <b-col col cols="4" sm="3" lg="2">Name</b-col>
        <b-col col cols="8" sm="9" lg="10">{{ customerName }}</b-col>
      </b-row> -->

		<!-- Mobile -->
		<b-row class="text-capitalize font-weight-bold mb-2">
			<b-col col cols="4" sm="3" lg="2">Mobile</b-col>
			<b-col col cols="8" sm="9" lg="10">{{ customerDetail.customerMobile }}</b-col>
		</b-row>

		<!-- Address -->
		<b-row class="text-capitalize mb-2">
			<b-col col cols="4" sm="3" lg="2">Address</b-col>
			<b-col col cols="8" sm="9">{{ addressLine }}</b-col>
		</b-row>

		<!-- City -->
		<b-row class="text-capitalize mb-2">
			<b-col col cols="4" sm="3" lg="2">City</b-col>
			<b-col col cols="8" sm="9">{{ customerDetail.city }}</b-col>
		</b-row>

		<!-- Pincode -->
		<b-row class="text-capitalize mb-2">
			<b-col col cols="4" sm="3" lg="2">Pincode</b-col>
			<b-col col cols="8" sm="9" lg="10">{{ customerDetail.pincode }}</b-col>
		</b-row>
	</div>
</template>

<script>
export default {
	props: {
		customerDetail: {
			type: Object,
			required: true,
		},
	},
	computed: {
		addressLine() {
			const { otherDetails, area, landmark } = this.customerDetail;

			let arrayOfAddressDetail = [];
			if (otherDetails) {
				arrayOfAddressDetail.push(otherDetails);
			}
			if (area) {
				arrayOfAddressDetail.push(area);
			}
			if (landmark) {
				arrayOfAddressDetail.push(landmark);
			}
			return arrayOfAddressDetail.join(", ");
		},
	},
};
</script>

<style></style>
