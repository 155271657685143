<template>
	<b-card no-body class="order-prescription-card">
		<b-row align-v="center" class="py-1" no-gutters>
			<!-- Image -->
			<b-col cols="4">
				<b-card class="image-card" body-class="p-0 my-2">
					<b-img v-bind="miniImageProps" :src="imageUrl" @click="showFullImageModal = true" class="fit cursor-pointer" alt="Prescription Image" />
				</b-card>
			</b-col>

			<!-- Info -->
			<b-col cols="8">
				<div class="mx-2 d-flex align-items-center">
					<!-- Product Title, Quantity & Unit -->
					<div class="d-flex flex-column">
						<!-- Prescription Name -->
						<div class="cart-product-title ">
							{{ name }}
						</div>

						<div class="cart-unit">(Prescription)</div>
					</div>
				</div>
			</b-col>
		</b-row>

		<!-- Full Image Modal -->
		<b-modal :title="name" size="md" v-model="showFullImageModal" hide-footer no-close-on-backdrop centered>
			<b-img-lazy v-bind="fullImageProps" :src="imageUrl" />
		</b-modal>
	</b-card>
</template>

<script>
import { mapGetters } from "vuex";
import { miniPrescriptionImageProps, fullPrescriptionImageProps } from "../../app-functions";
export default {
	props: {
		orderPrescription: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			showFullImageModal: false,
		};
	},
	computed: {
		...mapGetters({}),
		miniImageProps() {
			return miniPrescriptionImageProps();
		},
		fullImageProps() {
			return fullPrescriptionImageProps();
		},
		name() {
			return this.userPrescription.name;
		},
		userPrescription() {
			return this.orderPrescription.userPrescription;
		},
		imageUrl() {
			return this.userPrescription.prescriptionImage.blobUrl;
		},
	},
};
</script>

<style></style>
